<script>


import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import { authComputed, authMethods, authGetters } from '../../../state/helpers.js'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import { callApi } from '../../../msal/msalApi'
import Swal from 'sweetalert2'
import { useRoute } from 'vue-router'

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            data: null,
            userEmail: null,
            totalResults: 0,
            franchiseSelections: null,
            franchiseLocations: null,
            currentPage: 1,
            itemsPerPage: 10,
            selectFranchise: '',
            selectLocation: '',
            inputSearch: '',
            errorMessage: '',
            practiceRoles: '',
            showLoading: false,
            loading: false,
            memberOnly: true,
            status: {
                unknown: { state: false, value: 0 },
                active: { state: true, value: 1 },
                inactive: { state: false, value: 2 },
                deprecated: { state: false, value: 3 },
                test: { state: false, value: 4 },
                OutOfCompliance: { state: false, value: 5 },
                inDevelopment: { state: false, value: 6 },
                lead: { state: false, value: 7 }
            },
            selectFranchises: '',
            selectSelections: ''

        };
    },
    methods: {

        async call_API() {
            try {
                this.userEmail = this.getEmail || ''
                this.showLoading = true;
                this.loading = true;
                let url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${this.getEmail}&page=${this.currentPage}&pageSize=${this.itemsPerPage}&showActiveMembersOnly=${this.memberOnly || this.memberOnly}`;

                //let url = `${process.env.VUE_APP_WLT_API}/Associate/PracticeGroupMembersPaged?email=${this.userEmail}&page=${this.currentPage}&pageSize=${this.itemsPerPage}`;


                if (this.inputSearch) {
                    url += `&searchString=${this.inputSearch}`
                    console.log(url)
                }

                if (this.selectLocation && this.selectFranchise) {
                    url += `&franchiseLocationIdFilters[0]=${this.selectLocation}&franchiseLocationIdFilters[1]=${this.selectFranchise}`
                }
                if (this.selectFranchise) {
                    url += `&franchiseIdFilters[0]=${this.selectFranchise}`;
                }

                if (this.selectLocation) {
                    url += `&franchiseLocationIdFilters[0]=${this.selectLocation}`;
                }

                // Iteramos sobre las propiedades del objeto y comprobamos su valor
                let filterCount = 0;

                for (let status in this.status) {
                    if (this.status[status].state === true) {
                        if (filterCount === 0) {
                            url += `&franchiseLocationStatusFilters[0]=${this.status[status].value}&showActiveMembersOnly=${this.memberOnly || this.memberOnly}`;
                        } else {
                            url += `&franchiseLocationStatusFilters[${filterCount}]=${this.status[status].value}`;

                        }
                        filterCount++;
                    }
                }
                console.log(url)
                const response2 = await callApi(url, this.getToken)
                console.log(response2)
                // eslint-disable-next-line no-unused-vars
                //const response = await axios.get(url);
                const { items, totalItems, practiceRoles } = response2;
                this.data = items;
                this.totalResults = totalItems;
                this.franchiseLocations = response2.franchiseLocations;
                this.franchiseSelections = response2.franchiseSelections;

                this.practiceRoles = practiceRoles

                console.log(this.selectFranchise)
            } catch (error) {
                console.error(error.message);
                error.message = 'Error obtaining member list'
                Swal.fire(`<p class="h5">  ${error.message}</p>`, '', 'error')
            } finally {
                this.loading = false;
                this.showLoading = false;
            }
        },
        changePage(page) {
            this.currentPage = page;
            setTimeout(() => {
                this.call_API();
            }, 1000);
        },
        goToFirstPage() {
            this.changePage(1);
        },
        goToLastPage() {

            this.changePage(Math.ceil(this.totalResults / this.itemsPerPage));

        },
        async updateData() {

            this.call_API()
        },
        resetFilters() {
            this.inputSearch = '';
            this.selectLocation = ''
            this.selectFranchise = ''
            this.selectFranchises = ''
            this.selectSelections = ''
            for (let status in this.status) {
                this.status[status].state = false;
            }
            this.$router.replace({ path: '/Practitioner/Coach/Member' });

            this.call_API();
        },
        nextGroup() {
            if (this.currentPageGroup < this.pageGroups.length) {
                this.currentPage = this.pageGroups[this.currentPageGroup][0];
                this.currentPageGroup++;
            }
        },
        getClientRole(client) {
            let roles = [];
            let FRANCHISEE = client.userFranchiseRole_ID === "FRANCHISEE";
            let FRANCHISEADMIN = client.userFranchiseRole_ID === "FRANCHISEADMIN";

            // Verificar si el usuario es Group Owner o Group Admin
            if (FRANCHISEE) {
                roles.push('Group Owner');
            } else if (FRANCHISEADMIN) {
                roles.push('Group Admin');
            }

            // Verificar si el usuario tiene un rol de ubicación
            let hasLocationRole = false;
            if (client.isPractitioner) {
                roles.push('Practitioner');
                hasLocationRole = true;
            }
            if (client.isOfficeManager) {
                roles.push('Office Admin');
                hasLocationRole = true;
            }
            if (client.isCoachManager) {
                roles.push('Coach Manager');
                hasLocationRole = true;
            }
            if (client.isCoach) {
                roles.push('Coach');
                hasLocationRole = true;
            }

            // Agregar 'Associate' si el usuario no tiene un rol de ubicación específico
            if (!hasLocationRole && roles.length === 0) {
                roles.push('Associate');
            }

            // Ordenar los roles según el orden especificado
            const order = ['Group Owner', 'Group Admin', 'Practitioner', 'Office Admin', 'Coach Manager', 'Coach'];
            roles.sort((a, b) => order.indexOf(a) - order.indexOf(b));

            return roles.length > 1 ? roles.join(', ') : roles[0];
        },

        handleCurrentPageChange() {
            setTimeout(() => {
                this.call_API();
            }, 1000);
        },
        handleSelectFranchiseChange(franchise) {
            this.selectFranchise = franchise.target.value
            this.currentPage = 1;
            this.call_API();
        },
        handleSelectLocationChange(location) {
            this.selectLocation = location.target.value
            this.currentPage = 1;
            this.call_API();
        },
        handleMemberOnlyChange() {
            this.updateData();
        },
        handleStatusChange() {
            this.currentPage = 1;
            this.updateData();
        },
        handleInputSearchChange() {
            setTimeout(() => {
                this.updateData();
            }, 350);
        },
    },
    mounted() {
        const route = useRoute();
        // Accede a los datos de la ruta usando la variable 'route'
        this.inputSearch = route.query.inputSearch
        this.selectFranchise = route.query.selectFranchise
        this.selectLocation = route.query.selectLocation
        this.selectFranchises = route.query.selectFranchise ? route.query.selectFranchise : ''
        this.selectSelections = route.query.selectLocation ? route.query.selectLocation : ''
        this.memberOnly = route.query.memberOnly || this.memberOnly
        this.status = route.query.statusFilter ? JSON.parse(route.query.statusFilter) : this.status;
        this.call_API();
    },

    computed: {

        pageCount: function () {
            return Math.ceil(this.totalResults / this.itemsPerPage);
        },

        pagesToShow() {
            const pageCount = Math.ceil(this.totalResults / this.itemsPerPage);
            const pagesAroundCurrent = 2; // cambiar según sea necesario
            let startPage = Math.max(1, this.currentPage - pagesAroundCurrent);
            let endPage = Math.min(pageCount, this.currentPage + pagesAroundCurrent);
            const pages = [];

            // Agregar páginas al arreglo "pages"
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        },
        ...authComputed,
        ...authMethods,
        ...authGetters
    },

}
</script>
    
<template>
    <Layout>
        <PageHeader :title="Members" :items="items" />
        <div class="container-fluid bg-white pb-3">
            <form action="" class="pt-3">
                <div class="d-flex">
                    <button class="btn btn-primary" @click.prevent="updateData">Search</button>
                    <input type="text" class="col mx-2 form-control" name="firstname"
                        placeholder="FIRST NAME, LASTNAME , EMAIL" v-model="inputSearch" @input="handleInputSearchChange">

                    <select name="franchiseSelect" id="" class="col fw-bold mx-2 form-control"
                        @change="handleSelectFranchiseChange" v-model="selectFranchises">
                        <option value="">All Practice Groups</option>
                        <option :value="franchise.franchise_ID" v-for="(franchise, index) in franchiseSelections"
                            :key="index">
                            {{ franchise.franchiseName }}
                        </option>
                    </select>

                    <select name="selectLocation" id="" class="col fw-bold form-select" @change="handleSelectLocationChange"
                        v-model="selectSelections">
                        <option value="">All Locations</option>
                        <option :value="franchise.franchiseLocation_ID" v-for="(franchise, index) in franchiseLocations"
                            :key="index">
                            {{ franchise.locationName }} - {{ franchise.status }}
                        </option>
                    </select>

                    <div class="btn-group">
                        <button class="btn btn-dark btn-sm dropdown-toggle fw-bold" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class='bx bxs-cog  text-white mt-2'></i>
                        </button>
                        <ul class="dropdown-menu">
                            <p class="fw-bold mx-3">Members</p>

                            <div class="check-container mx-3  border-top border-dark">
                                <div class="form-check mb-1 mt-2">
                                    <input class="form-check-input" type="checkbox" value="status.value" id="unknown"
                                        v-model="memberOnly" @change="handleMemberOnlyChange">
                                    <label class="form-check-label fw-bold" for="unknown">
                                        Active Only
                                    </label>
                                </div>
                            </div>

                            <p class="fw-bold mx-3 border-bottom border-dark mt-2">Locations</p>

                            <div class="check-container mx-3">
                                <div class="form-check mb-1">
                                    <input class="form-check-input" type="checkbox" value="status.value" id="unknown"
                                        v-model="status.unknown.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="unknown">
                                        Unknown
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="1" id="Active"
                                        v-model="status.active.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="Active">
                                        Active
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="2" id="Inactive"
                                        v-model="status.inactive.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="Inactive">
                                        Inactive
                                    </label>
                                </div>
                                <!-- <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="3" id="Deprecated" v-model="status.deprecated.state" @change="handleStatusChange">
            <label class="form-check-label fw-bold" for="Deprecated">
              Deprecated
            </label>
          </div> -->
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="4" id="Test"
                                        v-model="status.test.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="Test">
                                        Test
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="5" id="outOfCompliance"
                                        v-model="status.OutOfCompliance.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="outOfCompliance">
                                        Out Of Compliance
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="6" id="inDevelopment"
                                        v-model="status.inDevelopment.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="inDevelopment">
                                        In Development
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="7" id="lead"
                                        v-model="status.lead.state" @change="handleStatusChange">
                                    <label class="form-check-label fw-bold" for="lead">
                                        Lead
                                    </label>
                                </div>
                            </div>
                        </ul>
                    </div>

                    <button class="btn border-dark mx-2" @click.prevent="resetFilters">Reset</button>
                </div>
            </form>

        </div>
        <div class="container-fluid bg-white vh-100">
            <div class="d-flex justify-content-between">
                <h4 class="fw-bolder">LIST OF MEMBERS</h4>
                <div>
                    <router-link class="btn btn-primary" :to="{ path: '/new-user', query: { franchiseID:selectFranchise} }">+ Add Member</router-link>
                </div>
            </div>

            <div>
                <div class="spinner-overlay text-center mt-5" v-if="loading">
                    <div class="spinner-border text-primary  custom-spinner" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div>Loading...</div>
                </div>
                <div v-if="!showLoading && data" class="table-responsive">
                    <table class="fw-bold table mt-4 bg-white" v-bind:class="{ 'table-overlay': loading }">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Practice Group</th>
                                <th>Role</th>
                                <th>Location</th>
                                <th>OPERATE</th>
                            </tr>
                        </thead>
                        <tbody v-for="(client, index) in data" :key="index">
                            <template v-if="index === 0 || client.email !== data[index - 1].email">
                                <tr>
                                    <th></th>
                                    <td>
                                        {{ client.firstName }} {{ client.lastName }}
                                    </td>
                                    <td>{{ client.email }}</td>
                                    <td>{{ client.franchiseName }}</td>
                                    <td>
                                        <div class="ellipsis fw-bold" :title="getClientRole(client)">{{
                                            getClientRole(client).split(',').length > 1
                                            ? getClientRole(client).split(',')[0] + '...'
                                            : getClientRole(client)
                                        }}</div>
                                    </td>
                                    <td>{{ client.locationName }}</td>
                                    <td>
                                        <router-link class="mx-1" :to="{
                                            path: '/edit-user/' + client.email + '/' + client.franchise_ID,
                                            query: {
                                                inputSearch: inputSearch,
                                                selectLocation: selectLocation,
                                                selectFranchise: selectFranchise,
                                                memberOnly: memberOnly,
                                                statusFilter: JSON.stringify(status)
                                            }
                                        }">
                                            VIEW
                                        </router-link>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td v-if="client.franchise_ID !== data[index - 1].franchise_ID">
                                        {{ client.franchiseName }}
                                    </td>
                                    <td v-else></td>
                                    <td>
                                        <div class="ellipsis fw-bold" :title="getClientRole(client)">{{
                                            getClientRole(client).split(',').length > 1
                                            ? getClientRole(client).split(',')[0] + '...'
                                            : getClientRole(client)
                                        }}</div>
                                    </td>
                                    <td>{{ client.locationName }}</td>
                                    <td>
                                        <router-link class="mx-1" :to="{
                                            path: '/edit-user/' + client.email + '/' + client.franchise_ID,
                                            query: {
                                                inputSearch: inputSearch,
                                                selectLocation: selectLocation,
                                                selectFranchise: selectFranchise,
                                                memberOnly: memberOnly,
                                                statusFilter: JSON.stringify(status)
                                            }
                                        }">
                                            VIEW
                                        </router-link>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>

                    <nav>
                        <nav>
                            <ul class="pagination pagination-sm justify-content-center">
                                <button class="btn mx-3"
                                    :class="{ 'btn-secondary': currentPage === 1, 'btn-info': currentPage !== 1 }"
                                    @click="goToFirstPage()"><i class='bx bx-first-page fw-bold'></i></button>

                                <button class="btn btn-info mx-3" :disabled="currentPage === 1"
                                    @click="changePage(currentPage - 1)">Previous</button>
                                <div v-for="(page, index) in pagesToShow" :key="index">
                                    <button class="btn mx-1"
                                        :class="{ 'btn-info': currentPage === page, 'btn-outline-info': currentPage !== page }"
                                        @click="changePage(page)">{{ page }}</button>
                                </div>
                                <button class="btn btn-primary " :disabled="currentPage * itemsPerPage >= totalResults"
                                    @click="changePage(currentPage + 1)">Next</button>

                                <button class="btn mx-5"
                                    :class="{ 'btn-info': currentPage === totalPages, 'btn-primary': currentPage !== totalPages }"
                                    @click="goToLastPage()"><i class='bx bx-last-page fw-bold'></i></button>

                            </ul>
                        </nav>
                    </nav>
                </div>
            </div>

        </div>

    </Layout>
</template>

<style>
.bg-soft {
    background-color: rgba(80, 165, 241, .25) !important;
}

.table-overlay {
    opacity: 0.1;
    pointer-events: none;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    animation: spin 1s linear infinite;
}

.spinner-border.custom-spinner {
    width: 6rem;
    height: 6rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-message {
    margin-top: 10px;
}

.spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    /* Set the maximum width for the element */
}

.ellipsis:hover {
    overflow: visible;
    z-index: 9999;
    position: relative;
}

.ellipsis:hover::before {
    content: attr(title);
    display: block;
    position: absolute;
    top: -30px;
    /* Set the position of the tooltip */
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
}
</style>


